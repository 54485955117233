import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import { authorPawel as author } from 'data/authors';
import { Post } from 'components/blog/content/styled';
import img from 'img/blog/covers/share-session-cover.png';
import img1 from 'img/blog/share-session.png';
import img2 from 'img/blog/start-playback.png';

const Content = () => {
  return (
    <Post>
      <p>We’ve just improved sharing session recordings in LiveSession! Here’s what’s new:</p>
      <ul>
        <li>
          <p>You can choose to share the recording with anyone, or with your teammates only:</p>
          <img src={img1} alt="share-session-screenshot" title="Share session to your teammates" />
        </li>
        <li>
          <p>
            You can set the recording to start at a particular moment. This is especially helpful
            when you’d like to point to a specific moment:
          </p>
          <img src={img2} alt="start-playback-screenshot" title="Choose a specific moment" />
        </li>
      </ul>
      <p>
        If you have any other ideas for improvement, we’re happy to hear from you! Feel free to get
        in touch via live chat or send us an email to{' '}
        <a href="mailto:hello@livesession.io">hello@livesession.io</a>
      </p>
    </Post>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Advanced session sharing',
  url: '/blog/advanced-session-sharing/',
  description:
    'Sharing session recordings with your colleagues just got easier. Check out new LiveSession features.',
  author,
  img,
  imgSocial: img,
  date: '2020-07-19',
  category: '',
  group: 'updates',
  timeToRead: 1,
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    timeToRead={frontmatter.timeToRead}
  />
);
